import { useNavigate } from "react-router-dom";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/parewa_logo1.PNG`;

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="bg-parewacreamcanprimary-100 dark:bg-zinc-900 dark:border-t dark:border-zinc-800 lg:grid lg:grid-cols-5 font-Comfortaa z-9">
      <div className="relative block h-32 lg:col-span-2 lg:h-full">
        <img
          src={parewaImg}
          alt="footerparewa"
          className="absolute inset-0 h-full w-full object-contain"
        />
      </div>

      <div className="px-4 py-16 sm:px-6 lg:col-span-3 lg:px-8">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
          <div>
            <p>
              <span className="text-sm uppercase tracking-wide text-parewamatrixprimary-500 dark:text-parewamatrixsecondary-500">
                {" "}
                Email us{" "}
              </span>

              <span className="block w-fit xxs:text-sm 2xl:text-lg font-medium text-zinc-900 dark:text-parewacreamcanprimary-100 hover:opacity-30 font-MontserratAlternates">
                <a href="mailto:info@parewainc.com">info@parewainc.com</a>
              </span>
            </p>

            <ul className="mt-8 space-y-5 font-bold">
              <li className="text-parewamatrixprimary-500 dark:text-parewamatrixsecondary-500 flex flex-col gap-1">
                Operating Days{" "}
                <span className="xxs:text-sm 2xl:text-lg text-zinc-900 dark:text-parewacreamcanprimary-200 font-medium font-MontserratAlternates">
                  Monday to Friday
                </span>
              </li>
              <li className="text-parewamatrixprimary-500  dark:text-parewamatrixsecondary-500 flex flex-col gap-1">
                Operating Hours{" "}
                <span className="xxs:text-sm 2xl:text-lg text-zinc-900 dark:text-parewacreamcanprimary-200 font-medium font-MontserratAlternates">
                  10am - 5pm
                </span>
              </li>
            </ul>

            <ul className="mt-8 flex-col">
              <p className="text-parewamatrixprimary-500 dark:text-parewamatrixsecondary-500 text-sm">
                Socials
              </p>
              <div className="flex gap-6 mt-2">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=61555923404677"
                    title="Facebook"
                    rel="noreferrer"
                    target="_blank"
                    className="text-black transition hover:opacity-30"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/parewaainc/"
                    rel="noreferrer"
                    target="_blank"
                    title="Instagram"
                    className="text-black transition hover:opacity-30"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>

                <li>
                  <a
                    href="https://twitter.com/"
                    rel="noreferrer"
                    target="_blank"
                    title="Twitter"
                    className="text-black transition hover:opacity-30"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      aria-hidden="true"
                      className="h-6 w-6"
                    >
                      <path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z" />
                    </svg>
                  </a>
                </li>
              </div>
            </ul>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {/* <div>
              <p className="font-bold text-parewamatrixprimary-500 dark:text-parewamatrixsecondary-500 text-lg">
                Services
              </p>

              <ul className="mt-6 space-y-4 text-sm font-bold">
                <li>
                  <a
                    href="/"
                    className="text-zinc-700 dark:text-parewacreamcanprimary-100 transition hover:opacity-30"
                  >
                    {" "}
                    HR Consulting{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="/"
                    className="text-zinc-700 dark:text-parewacreamcanprimary-100 transition hover:opacity-30"
                  >
                    {" "}
                    SEO Optimisation{" "}
                  </a>
                </li>
              </ul>
            </div> */}

            <div>
              <p className="font-bold text-parewamatrixprimary-500 dark:text-parewamatrixsecondary-500 text-lg">
                Company
              </p>

              <ul className="mt-6 space-y-4 text-sm">
                <li>
                  <a
                    href="/about"
                    rel="noreferrer"
                    target="_blank"
                    className="text-zinc-700 dark:text-parewacreamcanprimary-100 transition hover:opacity-30"
                  >
                    {" "}
                    About{" "}
                  </a>
                </li>

                <li>
                  <a
                    href="/team"
                    rel="noreferrer"
                    target="_blank"
                    className="text-zinc-700 dark:text-parewacreamcanprimary-100 transition hover:opacity-30"
                  >
                    {" "}
                    Meet the Team{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-12 pt-12">
          <div className="sm:flex sm:items-center sm:justify-between p-2 mb-4">
            <ul className="flex flex-wrap gap-4 text-xs">
              <li>
                <p
                  onClick={() => navigate("/terms-and-conditions")}
                  className="text-zinc-500 dark:text-white transition hover:opacity-30 cursor-pointer"
                >
                  {" "}
                  Terms & Conditions{" "}
                </p>
              </li>

              <li>
                <p
                  onClick={() => navigate("/privacy-policy")}
                  className="text-zinc-500 dark:text-white transition hover:opacity-30 cursor-pointer"
                >
                  {" "}
                  Privacy Policy{" "}
                </p>
              </li>

              <li>
                <p
                  onClick={() => navigate("/copyright-intellectual-property")}
                  className="text-zinc-500 dark:text-white transition hover:opacity-30 cursor-pointer"
                >
                  {" "}
                  Copyright & Intellectual Property{" "}
                </p>
              </li>
            </ul>

            <p className="mt-8 text-xs text-zinc-500 dark:text-white sm:mt-0">
              &copy; 2023. Parewa Incorporated. All rights reserved.
            </p>
          </div>
          <div className="bg-gradient-to-r from-zinc-700 via-parewacreamcanprimary-100 to-parewamatrixprimary-500 h-2 w-full rounded-full"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
