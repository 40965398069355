const Activity = ({ user, activities }) => {
  const { username } = user;

  return (
    <div
      className="
              flex flex-col
              w-full
              dark:text-white
              h-full
              p-2
          "
    >
      <section className="relative flex flex-col justify-center bg-zinc-100 bg-opacity-50 dark:bg-zinc-800 dark:bg-opacity-25 rounded-lg overflow-hidden antialiased">
        <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-6">
          <div className="flex flex-col justify-center divide-y divide-slate-200 py-6">
            <div className="w-full max-w-3xl mx-auto">
              <div className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:ml-[8.75rem] md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">
                {activities
                  .slice()
                  .reverse()
                  .map((activity, index) => (
                    <div key={activity._id} className="relative">
                      <div className="md:flex items-center md:space-x-4 mb-3">
                        <div className="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
                          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white dark:bg-zinc-800 shadow md:order-1">
                            {activity.action === "LIKE" && (
                              <svg
                                className="fill-emerald-500"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                              >
                                <path d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" />
                              </svg>
                            )}
                            {activity.action === "DISLIKE" && (
                              <svg
                                className="fill-red-500"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                              >
                                <path d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z" />
                              </svg>
                            )}
                          </div>
                          <time className="text-sm font-medium text-parewacreamcanprimary-200 md:w-28">
                            {new Date(activity.createdAt).toLocaleString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </time>
                        </div>
                        <div className="text-zinc-400 ml-14">
                          <span className="text-slate-900 dark:text-white font-bold">
                            {username}
                          </span>{" "}
                          {activity.action + "D"} the article
                        </div>
                      </div>
                      <div className="flex flex-col bg-white dark:bg-zinc-800 p-4 rounded border border-slate-200 dark:border-black text-slate-500 shadow ml-14 md:ml-44 dark:text-white">
                        <p className="font-medium">{activity.newsId.title}</p>
                        <p className="text-xs font-semibold">
                          Source: {activity.newsId.source_name}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Activity;
