import React, { useEffect, useState } from "react";
import FlipCard from "./FlipCard/FlipCard";
import { useGetNewsQuery } from "../../services/api/newsServices";
import Loading from "../../Loading.jsx";

const EverythingSinceALongTimeAgo = ({
  user,
  setButtonName,
  setShowLoginAlert,
  saveNews,
}) => {
  const [selectedNews, setSelectedNews] = useState([]);
  const { refetch, isSuccess, isLoading, isError, error, data } =
    useGetNewsQuery();

  useEffect(() => {
    if (isSuccess && data) {
      setSelectedNews(data?.data);
    }
    if (isLoading) {
      setSelectedNews([]);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000); // Fetch news every 30 seconds

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(interval);
    };
  }, [refetch]);

  return (
    <div className="flex xxs:gap-4 lg:gap-2 justify-evenly min-w-screen relative overflow-hidden py-10 overflow-x-scroll no-scrollbar">
      {isLoading ? (
        <Loading />
      ) : (
        selectedNews
          .slice(0, 5)
          .map((newsItem, index) => (
            <FlipCard
              saveNews={saveNews}
              setShowLoginAlert={setShowLoginAlert}
              news={newsItem}
              key={index}
              index={index}
              setButtonName={setButtonName}
            />
          ))
      )}
    </div>
  );
};

export default EverythingSinceALongTimeAgo;
