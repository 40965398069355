import { Tag } from "lucide-react";
import React from "react";

const NavButton = ({ navigate, type, Icon, label }) => (
  <button
    onClick={() => {
      navigate(`/news?type=${type}`);
    }}
    // className="mx-2 w-full flex p-1 items-center border-b-2 border-transparent hover:text-zinc-800 transition-colors duration-300 transform dark:hover:text-zinc-200 hover:border-zinc-500"
    className="mx-2 w-fit h-fit flex p-2 items-center bg-white shadow-md dark:bg-zinc-800 dark:hover:bg-opacity-25 rounded-lg hover:text-zinc-800 dark:hover:text-white transition-colors duration-300 transform hover:border-zinc-500"
  >
    <Tag className="mr-1 xxs:w-5 xxs:h-5 sm:w-5 sm:h-5" />
    <span className="xxs:text-[12px] sm:text-sm px-1 text-left w-52 flex justify-start">
      {label}
    </span>
  </button>
);

export default NavButton;
