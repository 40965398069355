import React from "react";

const ProfileCard = ({ name, email, occupation, linkedIn, image }) => {
  return (
    <div className="h-full dark:bg-zinc-800 bg-opacity-25 gap-6 flex items-center justify-center hover:shadow-md duration-300 rounded-full group">
      <div className="overflow-hidden group p-5 transition-all duration-500 transform">
        <div className="flex items-center gap-4">
          <img
            alt="profile"
            src={image}
            className="w-32 group-hover:w-36 group-hover:h-36 h-32 object-center group-hover:opacity-100 opacity-25 object-cover rounded-full transition-all duration-500 delay-500 transform"
          />
          <div className="w-fit transition-all transform duration-500">
            <h1 className="text-white dark:text-zinc-200 font-bold">{name}</h1>
            <p className="text-zinc-400">{occupation}</p>
            <span className="text-xs text-zinc-200 group-hover:opacity-100 opacity-0 transform transition-all delay-200 duration-500">
              {email}
            </span>
          </div>
        </div>
        <div className="absolute group-hover:bottom-1 delay-200 -bottom-16 transition-all duration-500 bg-zinc-600 dark:bg-zinc-100 right-1 rounded-lg group-hover:opacity-100 opacity-0">
          <div className="flex justify-evenly items-center gap-2 p-1 text-2xl text-white dark:text-zinc-600">
            <a
              href={linkedIn}
              target="_blank"
              className="group-hover:opacity-100"
              rel="noreferrer"
            >
              <svg
                viewBox="0 0 960 1000"
                fill="currentColor"
                height="1em"
                width="1em"
              >
                <path d="M480 20c133.333 0 246.667 46.667 340 140s140 206.667 140 340c0 132-46.667 245-140 339S613.333 980 480 980c-132 0-245-47-339-141S0 632 0 500c0-133.333 47-246.667 141-340S348 20 480 20M362 698V386h-96v312h96m-48-352c34.667 0 52-16 52-48s-17.333-48-52-48c-14.667 0-27 4.667-37 14s-15 20.667-15 34c0 32 17.333 48 52 48m404 352V514c0-44-10.333-77.667-31-101s-47.667-35-81-35c-44 0-76 16.667-96 50h-2l-6-42h-84c1.333 18.667 2 52 2 100v212h98V518c0-12 1.333-20 4-24 8-25.333 24.667-38 50-38 32 0 48 22.667 48 68v174h98" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
