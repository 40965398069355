import { Trophy } from "@styled-icons/bootstrap/Trophy";
import { Music } from "@styled-icons/boxicons-regular/Music";
import { LearningApp } from "@styled-icons/fluentui-system-regular/LearningApp";
import { News } from "@styled-icons/fluentui-system-regular/News";
import { NotebookSubsection } from "@styled-icons/fluentui-system-regular/NotebookSubsection";
import { PersonSupport } from "@styled-icons/fluentui-system-regular/PersonSupport";
import { StyleGuide } from "@styled-icons/fluentui-system-regular/StyleGuide";
import { Video } from "@styled-icons/fluentui-system-regular/Video";
import { Fitness } from "@styled-icons/ionicons-outline/Fitness";
import { Business } from "@styled-icons/ionicons-sharp/Business";
import { GameController } from "@styled-icons/ionicons-solid/GameController";
import { Dining } from "@styled-icons/material-outlined/Dining";
import { FamilyRestroom } from "@styled-icons/material-outlined/FamilyRestroom";
import { DevicesOther } from "@styled-icons/material-rounded/DevicesOther";
import { HomeHeart } from "@styled-icons/remix-line/HomeHeart";
import { TravelCase } from "@styled-icons/zondicons/TravelCase";
import {
  BadgeHelp,
  Code2,
  Compass,
  Home,
  Map,
  Popcorn,
  UsersRound,
} from "lucide-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fetchTypes } from "../../slice/typesSlice.js";
import SideBar, { SideBarItem } from "./Layout";

const SideBarMain = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const dispatch = useDispatch();
  const types = useSelector((state) => state.types.types);
  const status = useSelector((state) => state.types.status);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTypes());
    }
  }, [status, dispatch]);

  const typeIcons = {
    "arts_&_culture": <UsersRound size={25} />,
    "business_&_entrepreneurs": <Business size={25} />,
    "celebrity_&_pop_culture": <Popcorn size={25} />,
    "diaries_&_daily_life": <NotebookSubsection size={25} />,
    family: <FamilyRestroom size={25} />,
    "fashion_&_style": <StyleGuide size={25} />,
    "film_tv_&_video": <Video size={25} />,
    "fitness_&_health": <Fitness size={25} />,
    "food_&_dining": <Dining size={25} />,
    gaming: <GameController size={25} />,
    "learning_&_educational": <LearningApp size={25} />,
    music: <Music size={25} />,
    "news_&_social_concern": <News size={25} />,
    other_hobbies: <DevicesOther size={25} />,
    relationships: <HomeHeart size={25} />,
    "science_&_technology": <Code2 size={25} />,
    sports: <Trophy size={25} />,
    "travel_&_adventure": <TravelCase size={25} />,
    uncertain: <Map size={25} />,
    "youth_&_student_life": <Compass size={25} />,
    // Add more icons as needed
  };

  return (
    <div className="flex-initial xxs:hidden lg:flex lg:mt-24 2xl:mt-20 z-10 relative">
      <SideBar>
        {location.pathname === "/news" && type && (
          <Link to="/home">
            <SideBarItem icon={<Home size={25} />} text="Home" />
          </Link>
        )}

        <hr className="mt-5 w-full dark:border-parewacreamcanprimary-100" />
        {types.map((type) => (
          <Link
            className="capitalize"
            key={type}
            to={`/news?type=${encodeURIComponent(type)}`}
          >
            <SideBarItem
              icon={typeIcons[type]}
              text={type.replace(/_/g, " ")}
              type={type}
            />
          </Link>
        ))}
        <hr className="mt-10 w-full dark:border-parewacreamcanprimary-100" />
        <div className="relative top-100 my-10 ">
          <Link to="/verify-page">
            <SideBarItem icon={<BadgeHelp size={25} />} text="Verify Page" />
          </Link>
          <SideBarItem icon={<PersonSupport size={25} />} text="Support" />
        </div>
      </SideBar>
    </div>
  );
};

export default SideBarMain;
