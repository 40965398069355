import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../assets/output.css";
import { setLogin } from "../../slice/authSlice";
import LoginForm from "../Form/LoginForm/LoginForm";
import { hitApi } from "../services/hitApi";
import SuccessfulModal from "./Modals/SuccessfulModal";
import { motion } from "framer-motion";
import {
  setLikedNews,
  setTotalDislikes,
  setTotalLikes,
} from "../../slice/likedNewsSlice";

// function convertToBase64(file) {
//   return new Promise((resolve, reject) => {
//     const fileReader = new FileReader();
//     fileReader.readAsDataURL(file);
//     fileReader.onload = () => {
//       resolve(fileReader.result);
//     };
//     fileReader.onerror = (error) => {
//       reject(error);
//     };
//   });
// }

const bucketUrl = process.env.REACT_APP_BUCKET_URL;

const LoginImg = `${bucketUrl}/backend/assets/parewa_logo1.PNG`;

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isError, setIsError] = useState(false);

  // Add the useEffect hook here
  useEffect(() => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      hitApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    setIsError(false);
  }, []);

  const login = async (values, onSubmitProps) => {
    try {
      const loggedIn = await hitApi({
        method: "POST",
        url: "/users/login",
        data: values,
      });

      const loggedInResponse = loggedIn.data;

      onSubmitProps.resetForm();

      if (loggedInResponse) {
        dispatch(
          setLogin({
            user: loggedInResponse.user,
            token: loggedInResponse.token,
          })
        );

        hitApi.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${loggedInResponse.token}`;
        hitApi.defaults.headers.common["Content-Type"] = "application/json";
        setShowModal(true); // Show the modal
        setIsLoading(true); // Show the loading component

        // Store the token and user in localStorage or sessionStorage based on rememberMe
        if (rememberMe) {
          localStorage.setItem("token", loggedInResponse.token);
          localStorage.setItem(
            "user",
            JSON.stringify({
              _id: loggedInResponse.user._id,
              username: loggedInResponse.user.username,
            })
          );
        } else {
          sessionStorage.setItem("token", loggedInResponse.token);
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              _id: loggedInResponse.user._id,
              username: loggedInResponse.user.username,
            })
          );
        }

        let userId = loggedInResponse.user._id;

        // Fetch liked news and store it in Redux
        const likedNewsResponse = await hitApi.get(
          `/users/like-activity/${userId}`
        );

        dispatch(setLikedNews(likedNewsResponse.data.data.likedNews));
        dispatch(setTotalLikes(likedNewsResponse.data.data.totalLikes));
        dispatch(setTotalDislikes(likedNewsResponse.data.data.totalDislikes));

        setTimeout(() => {
          navigate(`/profile/${loggedInResponse.user._id}`);
          setIsLoading(false); // Hide the loading component
        }, 5000);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await login(values, onSubmitProps);
  };

  return (
    <div className="flex xxs:flex-col lg:flex-row h-screen w-full font-Comfortaa">
      <motion.div
        className="flex items-center p-1 xxs:h-1/2 lg:h-full"
        initial={{ x: -1000 }}
        animate={{ x: 0 }}
        transition={{ delay: 0.1, type: "tween" }}
      >
        <img
          className="w-full h-full object-contain"
          src={LoginImg}
          alt="logo"
        />
      </motion.div>

      <div className="bg-zinc-800 flex flex-col justify-center w-full h-full">
        <motion.div
          className="relative max-w-[400px] w-full flex flex-col justify-between mx-auto bg-white dark:bg-zinc-700 p-4 rounded-lg my-1 md:px-4"
          initial={{ y: -1000 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.1, type: "spring", stiffness: 120 }}
        >
          <div className="flex flex-col">
            <h2 className="text-xl font-bold text-center uppercase tracking-wider py-5 text-black dark:text-white antialiased">
              Welcome to{" "}
              <span className="text-black dark:text-parewacreamcansecondary-200">
                P
              </span>
              arewa
            </h2>

            <LoginForm
              buttonName="Login"
              onSubmit={handleFormSubmit}
              setRememberMe={setRememberMe}
            />
            {isError ? (
              <span className="flex text-xs p-1 mt-2 w-full text-red-600 justify-center">
                Invalid Credentials. Please try again.
              </span>
            ) : (
              ""
            )}
            <SuccessfulModal
              open={showModal}
              onClose={() => setShowModal(false)}
            >
              <div className="bg-white p-4 rounded">
                <h2>Login successful!</h2>
                {isLoading ? <div>Redirectingggg....</div> : null}
              </div>
            </SuccessfulModal>
          </div>

          <div className="xxs:flex-col md:flex-row flex justify-between mt-2 p-2 xxs:bg-white xxs:dark:bg-zinc-700 sm:dark:bg-transparent sm:bg-transparent">
            <div className="flex-col w-full flex justify-center px-1">
              <span className="text-xs text-left w-full p-1 text-zinc dark:text-zinc-300">
                Don't have an account?
              </span>
              <button
                id="sign_up_button"
                className="antialiased bg-zinc-100 hover:bg-zinc-200  dark:hover:bg-zinc-500 dark:bg-zinc-600 xxs:text-xs sm:text-sm rounded-lg p-2 w-full text-center"
                onClick={() => {
                  navigate(`/register`);
                }}
              >
                <span className="dark:text-parewacreamcanprimary-200">
                  Sign Up
                </span>{" "}
                Here
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LoginPage;
