import React from "react";
import { Carousel } from "react-responsive-carousel";
import { ToTitleCase } from "../NewsCard/utils/ToTitleCase";

const HeroSection = ({ newsWithimage }) => {
  return (
    <div className="h-fit m-2 max-w-screen xxs:hidden sm:flex">
      <Carousel
        autoPlay
        autoFocus
        infiniteLoop
        interval={3000}
        showThumbs={false}
        showIndicators={true}
        stopOnHover
        swipeable
        emulateTouch
        showArrows={false}
        showStatus={false}
        width={"100%"}
      >
        {newsWithimage.map((item, index) => (
          <div
            key={index}
            className="overflow-hidden bg-zinc-100 dark:bg-zinc-900 h-full sm:grid sm:grid-cols-2 sm:items-center p-2 mx-1 justify-center w-full mb-10"
          >
            <div className="p-8 md:p-12 lg:px-16 lg:py-24">
              <div className="mx-auto max-w-xl text-center ltr:sm:text-left rtl:sm:text-right flex flex-col bg-white dark:bg-zinc-800 p-6 bg-opacity-50 rounded-lg">
                <span className="news-card-category-body w-fit mb-2">
                  {ToTitleCase(item.type) || "Thinking"}
                </span>
                <h2 className="news-card-title">
                  {item.title ? (
                    new DOMParser()
                      .parseFromString(item.title, "text/html")
                      .body.textContent.split(" ")
                      .slice(0, 20)
                      .join(" ")
                  ) : (
                    <p className="text-xs">NO NEWS TITLE</p>
                  )}
                </h2>

                <p className="hidden news-card-description md:mt-4 md:block">
                  {item.description
                    ? new DOMParser()
                        .parseFromString(item.description, "text/html")
                        .body.textContent.slice(0, 70) + "..."
                    : ""}
                </p>

                <div className="mt-4 md:mt-8">
                  <a
                    href={item.source_url}
                    target="_blank"
                    rel="noreferrer"
                    className="news-card-learn-more-button"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>

            <div className="bg-white dark:bg-zinc-800 p-6 xl:bg-none xl:p-0 rounded-ss-[30px] md:rounded-ss-[60px]">
              <img
                alt=""
                src={item.image_source}
                className="h-96 w-52 object-cover rounded-ss-[30px] md:rounded-ss-[60px]"
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HeroSection;
