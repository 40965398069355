import { UserRound } from "lucide-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const SidebarContext = createContext();

const SideBar = ({ children }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const theme = useSelector((state) => state.auth.mode);

  // Set initial state based on window width
  const [expanded, setExpanded] = useState(window.innerWidth < 768);

  useEffect(() => {
    // Function to update state based on window width
    const handleResize = () => {
      if (window.innerWidth <= 1536) {
        setExpanded(false);
      } else {
        setExpanded(true);
      }
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const toggleSideBar = () => {
    setExpanded((curr) => !curr);
  };

  return (
    <aside
      className={`font-MontserratAlternates ${
        expanded ? "w-64" : "w-20"
      } h-full top-0 sticky ${theme === "dark" ? "bg-zinc-800" : ""}`}
    >
      <nav
        className={`flex flex-col h-full shadow-xl hover:shadow-md rounded-none p-1 ${
          theme === "dark"
            ? "bg-zinc-900 text-white border-r-2 border-zinc-800"
            : "text-black"
        }`}
      >
        <div
          className={`mt-4 flex flex-row items-center mx-2 ${
            expanded ? "justify-around" : ""
          } `}
        >
          <span
            className={`text-md lg:text-lg font-medium overflow-hidden transition-all duration-500 ease-in-out
            ${expanded ? "ml-5 mt-2" : "w-0"}`}
          >
            Categories
          </span>

          <button
            onClick={toggleSideBar}
            className={`p-1 rounded-full w-1/3 ml-4 bg-gradient-to-bl from-zinc-200 to-zinc-300 dark:from-zinc-800 dark:to-zinc-900 dark:border-zinc-500 border dark:border-opacity-25 dark:hover:border-zinc-600
            transition duration-500 ease-in-out transform motion-reduce:transition-none motion-reduce:hover:transform-none ${
              expanded
                ? "hover:-translate-x-2 mt-2"
                : "hover:translate-x-2 w-2 mt-5"
            }`}
          >
            <div className="p-1 cursor-pointer inline-flex">
              <span
                className={`${
                  expanded
                    ? "hidden"
                    : "absolute inset-0 rounded-full bg-parewamatrixprimary-300 transition peer-checked:bg-parewacreamcanprimary-300"
                }`}
              ></span>

              <span
                className={` ${
                  expanded
                    ? "hidden"
                    : "absolute -inset-2 start-2 -ml-2 h-6 w-6 rounded-full bg-zinc-800 ring-[6px] ring-inset ring-parewacreamcanprimary-100 transition-all peer-checked:start-8 peer-checked:w-1 peer-checked:bg-white peer-checked:ring-transparent"
                } `}
              ></span>
            </div>
          </button>
        </div>

        <SidebarContext.Provider value={{ expanded, toggleSideBar }}>
          <ul className={`flex-1 px-3 ${expanded ? "mt-14" : "mt-10"}`}>
            {children}
          </ul>
        </SidebarContext.Provider>

        <div className="flex flex-row border-t-2 border-solid hover:border-dashed dark:border-parewacreamcanprimary-100 p-3 font-Comfortaa">
          {user ? (
            <>
              <UserRound
                className={`${
                  expanded ? "flex-initial mt-1" : "w-10 h-10 mx-auto"
                }`}
              />
              <div
                className={`flex justify-between items-center relative overflow-hidden transition-all ${
                  expanded ? "w-52 ml-3" : "w-0"
                }`}
              >
                <div className="flex-col leading-4 w-[8rem] truncate">
                  <h4 className="row-start-1 col-span-2 font-semibold">
                    {user.firstName + " " + user.lastName}
                  </h4>
                  <span className="row-start-2 col-span-2 text-xs font-light">
                    {user.username}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <button
              className="flex items-center cursor-pointer"
              onClick={() => {
                navigate("/login");
              }}
            >
              <UserRound
                className={`${
                  expanded ? "flex-initial mt-1" : "w-10 h-10 mx-auto"
                }`}
              />
              <span
                className={`row-start-1 col-span-2 font-semibold ml-2 ${
                  expanded ? " " : "hidden"
                }`}
              >
                Login
              </span>
            </button>
          )}
        </div>
      </nav>
    </aside>
  );
};

export function SideBarItem({ icon, text, type, onClick }) {
  const { expanded } = useContext(SidebarContext);
  const location = useLocation();
  const isActive = location.search.includes(type);

  return (
    <li
      className={`relative flex items-center px-3 ${
        expanded ? "my-2" : "my-4 p-2"
      } 
      font-medium rounded-md cursor-pointer 
      transition-colors group
      ${
        isActive
          ? "bg-gradient-to-bl from-zinc-200 to-zinc-300 dark:from-zinc-800 dark:to-zinc-900 rounded-lg text-black dark:text-parewacreamcanprimary-100"
          : "hover:bg-zinc-300 dark:hover:bg-zinc-900 dark:text-white text-black rounded-lg"
      }`}
      onClick={onClick}
    >
      <span className="">{icon}</span>
      <span
        className={`
        overflow-hidden transition-all
        ${expanded ? "w-52 p-2 ml-1 xs:text-xs lg:text-sm" : "hidden"} `}
      >
        {text}
      </span>
      {/* {alert && (
        <div
          className={`
          absolute right-2 w-2 h-2 rounded-full 
          bg-zinc ${expanded ? "" : "top-2 left-2"}`}
        />
      )} */}

      {!expanded && (
        <div
          className="absolute rounded-lg px-2 py-1 ml-4 z-20
          bg-zinc-100 dark:bg-zinc-800 text-zinc-700 dark:text-zinc-100 xxs:text-xs xl:text-sm w-fit
          invisible -translate-y-5 transition-all duration-400 border border-zinc-200 dark:border-parewacreamcanprimary-200 ease-in-out
          group-hover:visible group-hover:opacity-100 group-hover:translate-y-4"
        >
          <span className="w-fit">{text}</span>
        </div>
      )}
    </li>
  );
}

export default SideBar;
