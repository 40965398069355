import React, { useEffect, useState } from "react";
import "./flipcard.css";
import CardButtons from "../../CardButtons/CardButtons";
import sourceImages from "../../../../utlis/sourceImages.js";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;

const FlipCard = ({
  news,
  index,
  setButtonName,
  setShowLoginAlert,
  saveNews,
}) => {
  const [randomIndex, setRandomIndex] = useState(Math.floor(Math.random() * 5));

  useEffect(() => {
    const interval = setInterval(() => {
      setRandomIndex(Math.floor(Math.random() * 5));
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  if (!news) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative">
      {index === randomIndex ? (
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front dark:border-zinc-800">
              <p className="text-zinc-800 dark:text-zinc-400 tracking-wider text-xs font-Comfortaa my-2">
                Hover Me
              </p>
            </div>
            <div className="flip-card-back relative">
              <div className="flex flex-col items-start p-6">
                <h3 className="mt-4 text-xl font-medium sm:text-2xl text-parewacreamcanprimary-200">
                  Lets get started
                </h3>

                <p className="mt-4 text-lg text-left font-sans font-thin dark:text-white text-black">
                  Your trusted source for the latest,{" "}
                  <span className="text-parewacreamcanprimary-200">valid</span>{" "}
                  news. <br /> Minimized misinformation, maximized reliability.
                  <br />
                  Remember{" "}
                  <span className="text-parewacreamcanprimary-200">
                    Parewa
                  </span>{" "}
                  for one stop solution for your news needs.
                </p>

                <p className="mt-8 font-semibold cursor-pointer w-full bg-parewacreamcanprimary-200 hover:bg-opacity-50 dark:bg-zinc-800 hover:dark:bg-opacity-50 p-2 rounded-lg tracking-wider text-black dark:text-white">
                  Click for Demo
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front dark:border-zinc-800">
              <p className="text-zinc-800 dark:text-zinc-400 tracking-wider text-xs font-Comfortaa my-2">
                Hover Me
              </p>
            </div>

            <div className="flip-card-back border-zinc-300 dark:border-zinc-800 flex flex-col justify-evenly bg-zinc-100 dark:bg-black items-start p-2">
              <div className="flex gap-2 p-2 items-center text-lg font-sans capitalize font-bold dark:text-white text-black">
                <img
                  src={sourceImages[news.source_name] || parewaImg}
                  width={25}
                  height={25}
                  className="object-cover object-center rounded-full w-10 h-10"
                  alt="source_img"
                ></img>
                <div className="flex flex-col items-start justify-start">
                  <span>{news.source_name}</span>
                  <p className="text-[10px] font-Comfortaa w-full flex items-start tracking-wide gap-2">
                    {/* <span>Published Date </span> */}
                    {new Date(news.pub_date).toLocaleDateString()}
                    {news.source_name !== "Annapurna Post" &&
                      news.source_name !== "Kathmandu Post" && (
                        <span className="text-[10px]">
                          {new Date(news.pub_date).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      )}
                  </p>
                </div>
              </div>
              {news.image_source ? (
                <div className="w-full h-18 justify-center flex">
                  <img
                    src={news.image_source}
                    alt="img"
                    className="rounded-lg object-cover object-center w-fit h-fit"
                  ></img>
                </div>
              ) : (
                ""
              )}
              <div className="dark:text-white text-black flex flex-col gap-4 items-center justify-start w-full">
                <p className="news-card-title w-full">
                  {news.title
                    ? new DOMParser()
                        .parseFromString(news.title, "text/html")
                        .body.textContent.split(" ")
                        .slice(0, 9)
                        .join(" ")
                    : ""}
                </p>
                <a
                  href={news.source_url}
                  target="_blank"
                  rel="noreferrer"
                  className="flex font-Comfortaa bg-parewacreamcanprimary-100 dark:bg-parewacreamcanprimary-300 dark:hover:bg-opacity-50 hover:bg-opacity-50 px-5 py-3 max-w-full text-left text-xs font-semibold capitalize text-zinc-900 transition mt-2"
                >
                  Learn More
                  <svg
                    className="w-4 h-4 ml-1"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>

              <CardButtons
                onSaveNews={saveNews}
                setShowLoginAlert={setShowLoginAlert}
                setButtonName={setButtonName}
                news={news}
                className="flex justify-evenly dark:text-zinc-600 text-zinc-300 w-full mb-2 font-Comfortaa"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlipCard;
