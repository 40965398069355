const bucketUrl = process.env.REACT_APP_BUCKET_URL;

const kathmanduPost = `${bucketUrl}/backend/assets/portalLogos/TKP.png`;
const annapurnaPost = `${bucketUrl}/backend/assets/portalLogos/अन्_नपूर्ण_पोस्ट्.png`;
const makaluKhabar = `${bucketUrl}/backend/assets/portalLogos/makalukhabar.jpg`;
const setopati = `${bucketUrl}/backend/assets/portalLogos/setopati.png`;
const gorkhapatra = `${bucketUrl}/backend/assets/portalLogos/gorkhapatra.png`;
const nagarikNews = `${bucketUrl}/backend/assets/portalLogos/nagarik_news.png`;
const imageKhabar = `${bucketUrl}/backend/assets/portalLogos/imagekhabar.jpg`;
const karobardaily = `${bucketUrl}/backend/assets/portalLogos/karobardaily.png`;
const himalayaTimes = `${bucketUrl}/backend/assets/portalLogos/eHimalayaTimes.png`;
const aawaajnews = `${bucketUrl}/backend/assets/portalLogos/aawajnews.png`;
const english_makalu_khabar = `${bucketUrl}/backend/assets/portalLogos/english_makalu_khabar.png`;
const rajdhanidaily = `${bucketUrl}/backend/assets/portalLogos/rajdhanidaily.png`;
const onlinekhabar = `${bucketUrl}/backend/assets/portalLogos/onlinekhabar.png`;
const newsOfNepal = `${bucketUrl}/backend/assets/portalLogos/newsofnepal.png`;
const news24 = `${bucketUrl}/backend/assets/portalLogos/news24.jpg`;
const nepalnews = `${bucketUrl}/backend/assets/portalLogos/nepalnews.jpg`;
const himalkhabar = `${bucketUrl}/backend/assets/portalLogos/himalkhabar.png`;
const hamrokhelkud = `${bucketUrl}/backend/assets/portalLogos/hamrokhelkud.jpg`;
const techmandu = `${bucketUrl}/backend/assets/portalLogos/techmandu.jpg`;
const ratopati = `${bucketUrl}/backend/assets/portalLogos/ratopati.jpeg`;
const therisingNepal = `${bucketUrl}/backend/assets/portalLogos/therisingnepal.png`;
const thehimalayantimes = `${bucketUrl}/backend/assets/portalLogos/thehimalayantimes.png`;
const dekhapadhi = `${bucketUrl}/backend/assets/portalLogos/dekhapadhi.png`;
const drishtinews = `${bucketUrl}/backend/assets/portalLogos/drishtinews.jpg`;
const reportersnepal = `${bucketUrl}/backend/assets/portalLogos/reportersNepal.png`;
const khabarhub = `${bucketUrl}/backend/assets/portalLogos/khabarhub.png`;
const ditible = `${bucketUrl}/backend/assets/portalLogos/ditible.webp`;
const bizmandu = `${bucketUrl}/backend/assets/portalLogos/bizmandu.ico`;
const clickmandu = `${bucketUrl}/backend/assets/portalLogos/clickmandu.jpg`;

const sourceImages = {
  "Aawaaj News": aawaajnews,
  "Kathmandu Post": kathmanduPost,
  "Annapurna Post": annapurnaPost,
  "Makalu Khabar English": english_makalu_khabar,
  "Makalu Khabar Nepali": makaluKhabar,
  "Online Khabar English": onlinekhabar,
  "Online Khabar Nepali": onlinekhabar,
  Setopati: setopati,
  Gorkhapatra: gorkhapatra,
  "Nagarik News": nagarikNews,
  "Himalaya Times": himalayaTimes,
  "Karobar Daily": karobardaily,
  "Image Khabar": imageKhabar,
  "Rajdhani Daily": rajdhanidaily,
  "News of Nepal": newsOfNepal,
  "News 24": news24,
  "Nepal News": nepalnews,
  "Himal Khabar": himalkhabar,
  "Hamro Khelkud": hamrokhelkud,
  Techmandu: techmandu,
  Ratopati: ratopati,
  "The Rising Nepal": therisingNepal,
  "The Himalayan Times": thehimalayantimes,
  Dekhapadhi: dekhapadhi,
  "Drishti News": drishtinews,
  "Reporters Nepal": reportersnepal,
  Khabarhub: khabarhub,
  "The Ditible": ditible,
  Bizmandu: bizmandu,
  Clickmandu: clickmandu,
  // add other source_name: image pairs as needed
};

export default sourceImages;
