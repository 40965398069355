import React, { useEffect, useState } from "react";
import { HeroVerify } from "./HeroVerify/HeroVerify.jsx";
import { Statistic } from "./Statistic/Statistic";
import { useCreateVerificationMutation } from "../services/api/newsServices.js";

const VerifyPageNew = () => {
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // const [verifiedData, setVerifiedData] = useState([]);

  const [createVerification, { isLoading, isError, error }] =
    useCreateVerificationMutation();

  useEffect(() => {
    if (isLoading) {
      console.log("isLoading...");
    }
    if (isError) {
      console.log("isError:", error);
    }
    // Scroll to the top of the page when the component mounts
    // window.scrollTo(0, 0);
  }, [isLoading, isError, error]); // Empty dependency array means this effect runs once on mount

  const handleSubmit = async () => {
    try {
      const formData = { url, title, description };

      // Check if any of the fields are empty
      if (!url.trim() || !title.trim() || !description.trim()) {
        console.log("Error: url, title, and description cannot be empty.");
        return; // Stop execution if any field is empty
      }

      const response = await createVerification(formData).unwrap();
      console.log("Response:", response);
      // setVerifiedData(response);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div className=" w-full h-full flex flex-col xxs:mt-16 sm:mt-20 font-Comfortaa">
      <HeroVerify
        setUrl={setUrl}
        setTitle={setTitle}
        setDescription={setDescription}
        url={url}
        title={title}
        description={description}
        handleSubmit={handleSubmit}
      />
      <Statistic />
    </div>
  );
};

export default VerifyPageNew;
