import { motion } from "framer-motion";
import { Home, Minus, Tag } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoginModal from "../HomePage/Modals/LoginModal.jsx";
import SuccessfulAlert from "../HomePage/Modals/SuccessfulAlert.jsx";
import { ToTitleCase } from "../HomePage/NewsCard/utils/ToTitleCase";
import Loading from "../Loading";
import SideBarMain from "../SideBar/SideBarMain.jsx";
import { useGetNewsByTypeQuery } from "../services/api/newsServices";
import { hitApi } from "../services/hitApi";
import FilterButtons from "./FilterButtons/FilterButtons";
import NewsCardForCategories from "./NewsCardForCategories";
import sourceImages from "../../utlis/sourceImages.js";

const NewsByCategories = ({ type }) => {
  const encodedType = encodeURIComponent(type);
  const itemsPerPage = 16;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSources, setSelectedSources] = useState([]);
  const [sortOption, setSortOption] = useState();
  const [sourceSelected, setSourceSelected] = useState(false);
  const [sortOptionSelected, setSortOptionSelected] = useState(false);
  const [timeOptionSelected, setTimeOptionSelected] = useState("");
  const [activeSource, setActiveSource] = useState("");
  const [activeSortOption, setActiveSortOption] = useState("");
  const [activeTimeOption, setActiveTimeOption] = useState("");
  const state = useSelector((state) => state.auth);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const [newsByCategories, setNewsByCategories] = useState([]);

  let user = state.user;

  const toggleSource = (source) => {
    if (source === "All") {
      setSelectedSources([]);
    } else {
      setSelectedSources([source]);
      // BELOW is for when SELECTING multiple SOURCES
      // setSelectedSources((prevSources) =>
      //   prevSources.includes(source)
      //     ? prevSources.filter((s) => s !== source)
      //     : [...prevSources, source]
      // );
    }
  };

  const {
    data: dataNewsType,
    isError,
    isSuccess,
    isLoading,
    error,
  } = useGetNewsByTypeQuery({
    type: encodedType,
    source: selectedSources.length > 0 ? selectedSources[0] : "All News",
    sortOption,
    timeOption: timeOptionSelected,
    page: currentPage,
    itemsPerPage,
  });

  let news = dataNewsType?.newsResults || [];

  useEffect(() => {
    if (isSuccess) {
      setNewsByCategories((prevNews) => {
        if (currentPage === 1) {
          return news;
        }
        const newNews = news.filter(
          (newArticle) =>
            !prevNews.some((prevArticle) => prevArticle._id === newArticle._id)
        );
        return [...prevNews, ...newNews];
      });
    }
    if (isError) {
      console.log("ERROR Occured! :( ", error?.error);
    }
  }, [isSuccess, isError, error, news]);

  useEffect(() => {
    setNewsByCategories([]);
    setCurrentPage(1);
  }, [encodedType, selectedSources, sortOption, timeOptionSelected]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const totalPages = dataNewsType?.totalPages || 0;

  const handleClick = (e, setActive) => {
    setActive(e.currentTarget.id);
  };

  const handleShowMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const likeNews = async (ACTION, newsId) => {
    if (!user) {
      // If there's no user in the state, show the login alert and stop the function
      setShowLoginAlert(true);
      return;
    }

    const userId = user._id;

    hitApi
      .patch(`/news/like-activity/${newsId}`, {
        user_id: userId,
        ACTION: ACTION,
      })
      .then((res) => {
        if (res.data && res.data._id) {
          const updatedNews = res.data;
          setNewsByCategories((prevNews) =>
            prevNews.map((item) =>
              item._id === updatedNews._id ? updatedNews : item
            )
          );
        }
        setShowModal(true);
        setTimeout(() => setShowModal(false), 3000);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const saveNews = (newsId) => {
    if (!user) {
      setShowLoginAlert(true);
      return;
    }

    const userId = user._id;

    hitApi
      .post(`/news/save/`, {
        user_id: userId,
        news_id: newsId,
        ACTION: newsByCategories.isSaved ? "DELETE" : "SAVE",
      })
      .then((res) => {
        setShowModal(true);
        setTimeout(() => setShowModal(false), 3000);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const buttons = [
    { filter: "All", name: "All News" },
    { filter: "Kathmandu Post", name: "Kathmandu Post" },
    { filter: "Annapurna Post", name: "Annapurna Post" },
    { filter: "Nagarik News", name: "Nagarik News" },
    { filter: "Gorkhapatra", name: "Gorkhapatra" },
    { filter: "Makalu Khabar Nepali", name: "Makalu Khabar Nepali" },
    {
      filter: "Makalu Khabar English",
      name: "Makalu Khabar English",
    },
    { filter: "Setopati", name: "Setopati" },
    { filter: "Himalaya Times", name: "Himalaya Times" },
    { filter: "Karobar Daily", name: "Karobar Daily" },
    { filter: "Image Khabar", name: "Image Khabar" },
    { filter: "Ratopati", name: "Ratopati" },
    { filter: "Online Khabar English", name: "Online Khabar English" },
    { filter: "Online Khabar Nepali", name: "Online Khabar Nepali" },
    { filter: "The Himalayan Times", name: "The Himalayan Times" },
    { filter: "Rajdhani Daily", name: "Rajdhani Daily" },
    { filter: "News 24", name: "News 24" },
    { filter: "Techmandu", name: "Techmandu" },
    { filter: "News of Nepal", name: "News of Nepal" },
    { filter: "Nepal News", name: "Nepal News" },
    { filter: "The Rising Nepal", name: "The Rising Nepal" },
    { filter: "Hamro Khelkud", name: "Hamro Khelkud" },
    { filter: "Himal Khabar", name: "Himal Khabar" },
    { filter: "Aawaaj News", name: "Aawaaj News" },
    { filter: "Dekhapadhi", name: "Dekhapadhi" },
    { filter: "Drishti News", name: "Drishti News" },
    { filter: "Reporters Nepal", name: "Reporters Nepal" },
    { filter: "Khabarhub", name: "Khabarhub" },
    { filter: "Ajako Artha", name: "Ajako Aartha" },
    { filter: "The Ditible", name: "The Ditible" },
    { filter: "BIZMANDU", name: "Bizmandu" },
    { filter: "Clickmandu", name: "Clickmandu" },
    { filter: "Techpana", name: "Techpana" },
  ];

  const sortButtons = [
    { id: "by-default", sortOption: "By Default", label: "By Default" },
    { id: "by-title", sortOption: "title", label: "Title" },
    {
      id: "by-date-newest",
      sortOption: "dateNewest",
      label: "Date (Newest First)",
    },
    {
      id: "by-date-oldest",
      sortOption: "dateOldest",
      label: "Date (Oldest First)",
    },
  ];

  const timeButtons = [
    {
      id: "within-the-hour",
      timeOption: "Within the hour",
      label: "Within the hour",
    },
    { id: "last-2-hours", timeOption: "Last 2 hours", label: "Last 2 hours" },
    {
      id: "last-24-hours",
      timeOption: "Last 24 hours",
      label: "Last 24 hours",
    },
    {
      id: "last-48-hours",
      timeOption: "Last 48 hours",
      label: "Last 48 hours",
    },
  ];

  buttons.sort((a, b) => a.name.localeCompare(b.name));
  sortButtons.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="flex bg-white dark:bg-zinc-900 min-h-screen relative font-Comfortaa max-w-screen">
      {/* SIDE BAR */}
      <SideBarMain />
      {/* SIDE BAR */}

      {/* BODY */}
      <section className="bg-white dark:bg-zinc-900 xxs:mt-16 sm:mt-20 overflow-scroll no-scrollbar">
        <div className="w-full p-2">
          {/* BREADCRUMBS */}
          <div className="flex justify-center w-full rounded-lg bg-zinc-100 dark:bg-zinc-800 shadow-md">
            <p className="text-[14px] font-semibold text-zinc-900 dark:text-zinc-200 flex subpixel-antialiased px-2">
              <Link
                to="/home"
                className="flex items-center gap-2 p-1 xxs:text-xs md:text-sm"
              >
                <Home className="w-4 h-4" />
                <span className="xl:mt-1">Home</span>
              </Link>
              <span className="p-2">
                <Minus
                  size={32}
                  className="text-parewacreamcanprimary-100 dark:text-parewacreamcanprimary-100"
                />
              </span>
              <Link
                to={`/news?type=${encodedType}`}
                className="flex items-center gap-2 p-2 xxs:text-xs md:text-sm"
              >
                <Tag className="h-4 w-4" />
                <span className="xl:mt-1">{ToTitleCase(type)}</span>
              </Link>
            </p>
          </div>
          {/* BREADCRUMBS */}
          {/* SORTING OPTIONS */}
          <div className="flex justify-start w-full rounded-lg">
            <div className="flex-col font-Comfortaa font-bold p-1 w-full">
              {/* According to SOURCE */}
              <div className="w-full flex dark:border-zinc-900">
                <div className="flex xxs:flex-col lg:flex-row justify-start xxs:items-start lg:items-center xxs:gap-0 lg:gap-2 w-full ">
                  <span className="sorting-category-label">Select Source</span>
                  <div className="sorting-category-buttons">
                    {buttons.map((button, index) => (
                      <FilterButtons
                        key={index}
                        id={button.filter}
                        onClick={(e) => {
                          toggleSource(button.filter);
                          setSourceSelected(true);
                          handleClick(e, setActiveSource);
                        }}
                        active={activeSource}
                      >
                        <span className="w-60 flex xxs:justify-start lg:justify-center items-center">
                          {sourceImages[button.name] && (
                            <img
                              src={sourceImages[button.name]}
                              alt={button.name}
                              className="w-6 h-6 mr-2 rounded-full object-cover"
                            />
                          )}
                          {button.name}
                        </span>
                      </FilterButtons>
                    ))}
                  </div>
                </div>
              </div>
              {/* According to SOURCE */}

              {/*According to SORTING OPTIONS */}
              {sourceSelected && (
                <div className="w-full flex xxs:flex-col 2xl:flex-row xxs:justify-start 2xl:justify-center items-center">
                  <div className="w-full flex dark:border-zinc-900">
                    <div className="sorting-category-container">
                      <span className="sorting-category-label">Sort By</span>
                      <div className="sorting-category-buttons">
                        {sortButtons.map((button, index) => (
                          <FilterButtons
                            key={index}
                            id={button.id}
                            onClick={(e) => {
                              setSortOption(button.sortOption);
                              setSortOptionSelected(true);
                              handleClick(e, setActiveSortOption);
                            }}
                            active={activeSortOption}
                          >
                            <span className="xxs:w-48 lg:w-52 flex xxs:justify-start lg:justify-center xxs:text-xs md:text-sm xl:text-base">
                              {button.label}
                            </span>
                          </FilterButtons>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="flex xxs:flex-col ml-4 items-center p-1">
                    <button
                      onClick={() => {
                        setSourceSelected(false);
                        setSortOptionSelected(false);
                        setSortOption("By Default");
                        setActiveSortOption("");
                      }}
                      className="text-[10px] text-black dark:text-white hover:underline"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}
              {/*According to SORTING OPTIONS */}

              {/* According to TIME */}
              {sortOptionSelected && (
                <div className="flex w-full xxs:flex-col 2xl:flex-row xxs:justify-start 2xl:justify-center items-center">
                  <div className="border-b w-full dark:border-zinc-900">
                    <div className="sorting-category-container">
                      <span className="sorting-category-label">Time</span>
                      <div className="sorting-category-buttons">
                        {timeButtons.map((button, index) => (
                          <FilterButtons
                            key={index}
                            id={button.id}
                            onClick={(e) => {
                              setTimeOptionSelected(button.timeOption);
                              handleClick(e, setActiveTimeOption);
                            }}
                            active={activeTimeOption}
                          >
                            <span className="xxs:w-48 lg:w-52 flex xxs:justify-start lg:justify-center xxs:text-xs md:text-sm xl:text-base">
                              {button.label}
                            </span>
                          </FilterButtons>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex xxs:flex-col ml-4 items-center p-1">
                    <button
                      onClick={() => {
                        setSortOptionSelected(false);
                        setTimeOptionSelected("");
                        setActiveTimeOption("");
                      }}
                      className="text-[10px] text-black dark:text-white hover:underline"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}
              {/* According to TIME */}
            </div>
          </div>
          {/* SORTING OPTIONS */}
        </div>

        {/* NEWS HERE */}
        <div className="flex-col flex items-center xl:py-4 relative w-full">
          <div className="w-full">
            {isLoading ? (
              <Loading />
            ) : newsByCategories.length > 0 ? (
              <div className="grid 4xl:grid-cols-4 2xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 items-center gap-6 p-2 font-sans">
                {newsByCategories.map((news) => (
                  <NewsCardForCategories
                    key={news._id}
                    news={news}
                    onLikeNews={likeNews}
                    onSaveNews={saveNews}
                    showLoginAlert={showLoginAlert}
                    setShowLoginAlert={setShowLoginAlert}
                    setButtonName={setButtonName}
                    buttonName={buttonName}
                  />
                ))}
              </div>
            ) : (
              <motion.div
                className="title"
                initial={{ y: -200 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5, type: "spring", stiffness: 120 }}
              >
                No news in this range
                <p className="text-xs mt-2 dark:text-white text-zinc-400 normal-case">
                  Try different parameters
                </p>
              </motion.div>
            )}
          </div>
        </div>
        {/* Show More Button */}
        {currentPage < totalPages && (
          <div className="w-full mt-4 p-2 mb-2 rounded-lg font-Comfortaa">
            <div className="flex justify-center">
              <button
                onClick={handleShowMore}
                className="px-4 py-2 bg-zinc-100 dark:bg-zinc-900 bg-gradient-to-t from-zinc-100 dark:from-zinc-800 to-zinc-50 dark:to-zinc-900 shadow-lg text-black dark:text-white rounded-full hover:bg-opacity-50 hover:text-opacity-25 dark:hover:bg-opacity-50 dark:hover:text-opacity-25"
              >
                Show More
              </button>
            </div>
          </div>
        )}
        {/* Show More Button */}
        {/* NEWS HERE */}
      </section>

      {/* BODY */}
      {showModal && (
        <div className="fixed top-auto left-auto inset-2 z-50 max-h-fit max-w-sm">
          <SuccessfulAlert
            buttonName={buttonName}
            setShowModal={setShowModal}
          />
        </div>
      )}
      {showLoginAlert && (
        <div className="fixed inset-2 z-50 max-h-full  rounded-lg max-w-screen bg-zinc-100 dark:bg-zinc-900 dark:bg-opacity-90 bg-opacity-80 font-Comfortaa flex items-center justify-center">
          <div className="h-fit flex flex-col items-center bg-parewaBackground bg-no-repeat bg-left">
            <div className="flex p-6 w-full justify-center bg-zinc-100 dark:bg-black dark:bg-opacity-80 text-black dark:text-white shadow-md mb-2 rounded-lg">
              You are about to{" "}
              <span className="text-parewacreamcanprimary-200 mr-1 ml-1">
                {buttonName}
              </span>{" "}
              a news article
            </div>
            <LoginModal setShowLoginAlert={setShowLoginAlert} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsByCategories;
