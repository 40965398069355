import React, { useEffect } from "react";
import ProfileCard from "./ProfileCard/ProfileCard.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "../../../slice/authSlice";
import { Moon, Quote, Sun } from "lucide-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { motion } from "framer-motion";

const priyesh = `${process.env.REACT_APP_BUCKET_URL}/backend/assets/pic/priyesh.jpg`;
const bgPhoto = `${process.env.REACT_APP_BUCKET_URL}/backend/assets/parewa_logo1.PNG`;

const cofounders = [
  {
    name: "Aniket Sharma",
    email: "aniket.poudel@gmail.com",
    occupation: "Co-Founder",
    linkedIn: "https://www.linkedin.com/in/aniket-sharma-77b32819a/",
    quote: "Navigate the Nepali News Universe with Ease!",
    photo: "https://avatars.githubusercontent.com/u/55676876?v=4",
  },
  {
    name: "Abhishek Jaiswal",
    email: "abhishekjas2001@gmail.com",
    occupation: "Co-Founder",
    linkedIn: "https://www.linkedin.com/in/abhishek-jaswl/",
    quote:
      "Soar through information effortlessly, your digital pigeon to the world's insights.",
    photo: "https://avatars.githubusercontent.com/u/55676876?v=4",
  },
  {
    name: "Priyesh Shrestha",
    email: "priyeshshrestha9@gmail.com",
    occupation: "Co-Founder",
    linkedIn: "https://www.linkedin.com/in/priyeshstha/",
    quote:
      "Get reliable news instantly with Parewa, ensuring every story is legit.",
    photo: priyesh || "https://avatars.githubusercontent.com/u/55676876?v=4",
  },
];

const Welcome = () => {
  const navigate = useNavigate();
  const theme = useSelector((state) => state.auth.mode); // get theme from Redux store
  const dispatch = useDispatch();
  const handleGetStarted = () => {
    navigate("/home");
  };
  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const toggleTheme = () => {
    dispatch(
      setMode(theme === "light" ? "dark" : "light") // dispatch action to change theme in Redux store
    );
  };

  return (
    <div className="relative bg-gradient-to-r from-zinc-900 to-parewacreamcanprimary-200 h-screen max-w-screen text-white overflow-hidden">
      <div className="absolute inset-0 dark:bg-zinc-900">
        <img
          src={bgPhoto}
          alt="Background"
          className="object-contain object-center w-full h-full opacity-30"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      <div className="flex justify-center h-full font-Comfortaa">
        <div className="relative flex flex-col xs:gap-2 md:gap-y-20 justify-center items-center text-center">
          <div className="w-fit flex-col flex xxs:p-0 sm:p-4">
            <h1 className="animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-5 xxs:text-xl sm:text-5xl text-white font-bold">
              Welcome to Parewa
            </h1>
            <div className="items-center justify-center w-10 h-10 mx-auto mt-10">
              <button
                className="hover:scale-125 transition-all hover:shadow-xl duration-300 rounded-full"
                onClick={toggleTheme}
              >
                {theme === "light" ? <Moon size={25} /> : <Sun size={25} />}
              </button>
            </div>
          </div>
          <motion.button
            onClick={handleGetStarted}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            // whileInView={{ opacity: 1 }}
            // transition={{ delay: 1.5, duration: 1.5 }}
            transition={{
              delay: 0.4,
              type: "spring",
              stiffness: 120,
              duration: 1.5,
            }}
            className="bg-parewacreamcanprimary-400 dark:bg-zinc-700 dark:hover:bg-opacity-50 dark:hover:text-zinc-200 text-gray-900 hover:bg-parewacreamcanprimary-100 py-2 px-6 rounded-full xxs:text-base xxs:font-bold sm:text-lg sm:font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg font-mono w-full"
          >
            Get Started
          </motion.button>
        </div>
      </div>

      <div className="items-center gap-2 absolute bottom-0 right-10 xxs:hidden lg:flex lg:left-1/3">
        <div className="h-fit w-full">
          <Carousel
            autoPlay
            autoFocus
            infiniteLoop
            interval={3000}
            showThumbs={false}
            showIndicators={false}
            stopOnHover
            swipeable
            emulateTouch
            showArrows={false}
            showStatus={false}
            width={"100%"}
          >
            {cofounders.map((cofounder, index) => (
              <div key={index} className="h-full p-2">
                <div className="flex items-center xxs:gap-2 sm:gap-4">
                  <div className="flex-1">
                    <p className="xxs:text-xs sm:text-sm 2xl:text-lg xxs:p-1 sm:p-2 text-end">
                      <span className="flex justify-end mb-2">
                        <Quote size={15} className="text-zinc-300" />
                      </span>
                      <span className="text-zinc-300 text-center font-sans font-medium tracking-wide">
                        {" "}
                        {cofounder.quote}
                      </span>

                      <span className="flex justify-end mt-2">
                        <Quote size={15} className="text-zinc-300" />
                      </span>
                    </p>
                  </div>
                  <ProfileCard
                    name={cofounder.name}
                    email={cofounder.email}
                    occupation={cofounder.occupation}
                    linkedIn={cofounder.linkedIn}
                    image={cofounder.photo}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
