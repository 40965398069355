import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../slice/authSlice.js";
import { hitApi } from "../components/services/hitApi.js";
import { jwtDecode } from "jwt-decode";

const useToken = () => {
  const dispatch = useDispatch();
  const reduxToken = useSelector((state) => state.auth.token);

  const getToken = () => {
    if (reduxToken) return reduxToken;
    return sessionStorage.getItem("token") || localStorage.getItem("token");
  };

  const [token, setTokenState] = useState(getToken());

  useEffect(() => {
    const tokenFromStorage = getToken();
    if (tokenFromStorage && !reduxToken) {
      dispatch(setToken(tokenFromStorage));
      const userId = getUserIdFromToken(tokenFromStorage);
      fetchUserDetails(tokenFromStorage, userId);
    }
    setTokenState(tokenFromStorage);
  }, [reduxToken, dispatch]);

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userId; // Adjust this based on your token's payload structure
    } catch (error) {
      console.error("Error decoding token:", error.message);
      return null;
    }
  };

  const fetchUserDetails = async (token, userId) => {
    try {
      const response = await hitApi.get(`/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setUser(response.data));
    } catch (error) {
      console.error("Error fetching user details:", error.message);
    }
  };

  return token;
};

export default useToken;
