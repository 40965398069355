import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import UserProfile from "./Pages/Profile/Profile-updated.js";
import Saved from "./Pages/Saved/Saved.jsx";
import UserSettingsRTK from "./Pages/UserSettings/UserSettingsRTK";

const MainHeroSection = ({ activeComponent, user, setActiveComponent }) => {
  return (
    <div className="flex flex-col w-full dark:text-white p-2 h-fit">
      <Breadcrumbs
        activeComponent={activeComponent}
        setActiveComponent={setActiveComponent}
      />

      <div className="flex items-center rounded-lg justify-center max-w-screen p-2">
        {/* Active Component selected based on User clicks */}
        {activeComponent === "UserSettings" ? (
          <UserSettingsRTK
            user={user}
            setActiveComponent={setActiveComponent}
          />
        ) : activeComponent === "Saved" ? (
          <Saved user={user} />
        ) : activeComponent === "ForYou" ? (
          <div>FOR YOU</div>
        ) : activeComponent === "Profile" ? (
          <UserProfile user={user} setActiveComponent={setActiveComponent} />
        ) : (
          <div className="xxs:text-xs md:text-lg hover:text-md uppercase hover:scale-105 hover:rounded-lg hover:p-4 p-2 bg-zinc-200 dark:bg-opacity-10 dark:rounded-lg bg-opacity-50 items-center flex my-[50%] mx-auto duration-500 transition-all">
            What would you like to do today?
          </div>
        )}
      </div>
    </div>
  );
};

export default MainHeroSection;
