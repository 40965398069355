import React from "react";
import { ToTitleCase } from "../NewsCard/utils/ToTitleCase";
import { Carousel } from "react-responsive-carousel";
import { ChevronLeft, ChevronRight } from "lucide-react";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const bg = `${bucketUrl}/backend/assets/wallpapers/raju-photo.jpg`;

// Custom Next Arrow Component
const CustomNextArrow = ({ onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="absolute z-10 top-0 h-full right-3 bg-zinc-100 bg-opacity-5 dark:bg-black dark:bg-opacity-5 dark:hover:bg-opacity-25 ease-in-out transition-all duration-300 text-zinc-600 hover:bg-opacity-25 dark:text-white p-2 text-lg"
  >
    <ChevronRight size={20} />
  </button>
);

// Custom Prev Arrow Component
const CustomPrevArrow = ({ onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="absolute z-10 left-3 h-full bg-zinc-100 bg-opacity-5 dark:bg-black dark:bg-opacity-5 dark:hover:bg-opacity-25 ease-in-out transition-all duration-300 text-zinc-600 hover:bg-opacity-25 dark:text-white p-2 text-lg"
  >
    <ChevronLeft size={20} />
  </button>
);

const MobileHeroSection = ({ newsWithimage }) => {
  return (
    <div className="xxs:-mx-4 sm:mx-4 overflow-hidden sm:hidden">
      <Carousel
        autoPlay
        autoFocus
        infiniteLoop
        interval={3000}
        showThumbs={true}
        showIndicators={true}
        stopOnHover
        swipeable
        emulateTouch
        showArrows={true}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && <CustomPrevArrow onClick={onClickHandler} />
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && <CustomNextArrow onClick={onClickHandler} />
        }
        showStatus={false}
        width={"100%"}
      >
        {newsWithimage.map((item, index) => (
          <div
            key={index}
            className="relative bg-cover bg-center h-96 dark:bg-zinc-900 sm:grid sm:grid-cols-2 sm:items-center justify-center w-full"
            style={{
              backgroundImage: `url(${bg})`,
              height: 250,
            }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            <div className="relative z-10 p-6 w-full h-full">
              <div className="mx-auto max-w-xl h-[95%] text-center ltr:sm:text-left rtl:sm:text-right flex flex-col bg-zinc-100 dark:bg-zinc-800 p-6 bg-opacity-50 dark:bg-opacity-50 rounded-lg">
                <span className="news-card-category-body w-fit mb-2">
                  {ToTitleCase(item.type) || item.category[0] || "Thinking"}
                </span>
                <h2 className="news-card-title">
                  {item.title ? (
                    new DOMParser()
                      .parseFromString(item.title, "text/html")
                      .body.textContent.split(" ")
                      .slice(0, 20)
                      .join(" ")
                  ) : (
                    <p className="text-xs">NO NEWS TITLE</p>
                  )}
                </h2>

                <div className="flex justify-center items-center flex-wrap p-2 mt-4 ">
                  <a
                    className="text-black dark:text-[#d8b858] font-Comfortaa text-sm bg-zinc-300 dark:bg-zinc-700 inline-flex items-center duration-300 hover:bg-opacity-30 dark:hover:bg-opacity-50 transition-all dark:hover:bg-zinc-700 p-2 rounded-lg"
                    href={`${item.source_url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MobileHeroSection;
