import CardButtons from "../CardButtons/CardButtons.jsx";
import { ToTitleCase } from "./utils/ToTitleCase.js";
import { useState } from "react";
import sourceImages from "../../../utlis/sourceImages.js";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;

const NewsWithImage = ({
  news,
  onLikeNews,
  onSaveNews,
  showLoginAlert,
  setShowLoginAlert,
  setButtonName,
  buttonName,
}) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  return (
    <div
      key={news._id}
      className="flex xxs:w-full xxs:h-full bg-zinc-100 border-parewacreamcanprimary-200 rounded-lg hover:shadow-2xl dark:hover:shadow-md dark:hover:shadow-zinc-700 duration-200 dark:bg-zinc-800"
    >
      <article className="flex xxs:flex-col lg:flex-row w-full">
        {/* DATE */}
        <div className="p-2 xxs:[writing-mode:_horizontal-lr] lg:[writing-mode:_vertical-lr] lg:rotate-180 font-Comfortaa">
          <div className="flex items-center justify-between gap-4 text-xs font-bold uppercase text-zinc-900 dark:text-white">
            <p className="text-xs">{new Date(news.pub_date).getFullYear()}</p>

            <span className="w-px flex-1 bg-parewacreamcanprimary-200"></span>

            <p className="text-xs">
              {new Date(news.pub_date).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
              })}
            </p>
          </div>
        </div>
        {/* DATE */}

        {/* IMAGE */}
        {imageLoaded && news.image_source && (
          <div className="hidden sm:block sm:basis-56 sm:w-full sm:h-20 lg:h-full">
            <img
              alt="img_news"
              src={news.image_source}
              className="aspect-square h-full w-full object-cover"
              onError={() => setImageLoaded(false)}
            />
          </div>
        )}
        {/* IMAGE */}

        <div className="flex flex-1 flex-col justify-around w-full">
          {/* TITLE AND DESCRIPTION */}
          <div className="flex flex-col h-full w-full mt-2">
            <span className="xxs:ml-4 lg:ml-6 w-fit news-card-category-body">
              {ToTitleCase(news.type || news.category[0] || "Thinking")}
            </span>
            <div className="xxs:w-full xxs:p-4 lg:p-6 ">
              <h3 className="flex news-card-title mt-2 cursor-text">
                {news.title
                  ? new DOMParser()
                      .parseFromString(news.title, "text/html")
                      .body.textContent.split(" ")
                      .slice(0, 20)
                      .join(" ")
                  : ""}
              </h3>

              <p className="mt-4 news-card-description md:h-fit 2xl:h-40">
                {news.description
                  ? new DOMParser()
                      .parseFromString(news.description, "text/html")
                      .body.textContent.slice(0, 50) + "..."
                  : ""}
              </p>
            </div>
          </div>
          {/* TITLE AND DESCRIPTION */}

          <div className="flex flex-col md:flex-col w-full justify-between font-Comfortaa">
            {/* Published Time and News Source */}
            <div className="items-center w-full px-5 mx-auto py-2">
              <div className="xxs:flex-col xs:flex-row md:flex-row lg:flex-col xl:flex-row flex justify-between">
                <div className="flex-col cursor-vertical-text p-1">
                  <p className="text-xs text-zinc-500">
                    {new Date(news.pub_date).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                  <p className="xxs:text-[9px] lg:text-[10px] font-light text-zinc-500">
                    Time
                  </p>
                </div>

                <div className="flex p-1 overflow-hidden">
                  <p className="xxs:flex justify-end text-[12px] sm:text-zinc-900 items-center">
                    <img
                      src={sourceImages[news.source_name] || parewaImg}
                      width={25}
                      height={25}
                      className="object-cover object-center rounded-full xxs:w-5 xxs:h-5 lg:w-6 lg:h-6"
                      alt="source_img"
                    ></img>

                    <a
                      href={`${news.source_url}`}
                      target="_blank"
                      rel="noreferrer"
                      className="news-card-source ml-2"
                    >
                      {news.source_name}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* Published Time and News Source */}

            {/* BUTTONS AND READ MORE */}
            <div className="flex sm:flex-col 3xl:flex-row w-full font-Comfortaa">
              <CardButtons
                news={news}
                // onLikeNews={onLikeNews}
                onSaveNews={onSaveNews}
                showLoginAlert={showLoginAlert}
                setShowLoginAlert={setShowLoginAlert}
                setButtonName={setButtonName}
                buttonName={buttonName}
                className="flex justify-evenly dark:text-zinc-600 text-zinc-300 xxs:w-2/3 sm:w-full 3xl:w-2/3 mb-2"
              />
              <a
                href={`${news.source_url}`}
                target="_blank"
                rel="noreferrer"
                className="flex bg-parewacreamcanprimary-100 dark:bg-parewacreamcanprimary-300 dark:hover:bg-opacity-50 hover:bg-opacity-50 px-5 py-3 xxs:w-1/3 sm:w-full 3xl:w-2/3 text-left text-xs font-semibold capitalize text-zinc-900 transition"
              >
                Learn more
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
            {/* BUTTONS AND READ MORE */}
          </div>
        </div>
      </article>
    </div>
  );
};

export default NewsWithImage;
