import { createSlice } from "@reduxjs/toolkit";
import { hitApi } from "../components/services/hitApi";

const initialState = {
  //This data will be accessible throughout the entire application and
  //can grab it wherever needed. This will be stored in the GLOBALSTATE!
  mode: "light",
  user: null,
  token: null,
  posts: [],
  news: [],
  savedNews: [],
  searchTerm: "",
  searchedNews: [],
  showLoginAlert: false, // new state for LoginModal visibility
  showModal: false, // new state for SuccessfulModal visibility
};
// All this will be stored in Local Storage.

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      hitApi.defaults.headers.common.Authorization = "";
      state.user = null;
      state.token = null;
      state.mode = "light";
    },
    setPosts: (state, action) => {
      state.posts = action.payload.posts;
    },
    setPost: (state, action) => {
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.post_id) return action.payload.post;
        return post;
      });
      state.posts = updatedPosts;
    },
    setNewsInStore: (state, action) => {
      state.news = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setUpdatedNewsInStore: (state, action) => {
      state.news = state.news.map((news) => {
        if (action.payload._id === news._id) {
          return action.payload;
        }
        return news;
      });
      if (state.user) {
        state.user.savedCount = state.news.filter((n) => n.isSaved).length;
      }
    },
    setSavedNewsInStore: (state, action) => {
      state.savedNews = action.payload;
    },
    setSearchedNewsInStore: (state, action) => {
      state.searchedNews = action.payload;
    },
    removeSavedNews: (state, action) => {
      state.savedNews = state.savedNews.filter(
        (news) => news._id !== action.payload
      );
    },
    setShowLoginAlert: (state, action) => {
      state.showLoginAlert = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
});

export const {
  setMode,
  setLogin,
  setToken,
  setUser,
  setLogout,
  setPosts,
  setPost,
  setNewsInStore,
  setUpdatedNewsInStore,
  setSavedNewsInStore,
  setSearchTerm,
  setSearchedNewsInStore,
  removeSavedNews,
  setShowLoginAlert,
  setShowModal,
} = authSlice.actions;

export default authSlice.reducer;
