import { Form, Formik } from "formik";
import { BadgeCheck, GitCommitVertical } from "lucide-react";
// eslint-disable-next-line
import NepaliDate from "nepali-date";
import React from "react";
import * as yup from "yup";
import FormikInput from "../../../Form/FormikInput";
import FormikTextArea from "../../../Form/FormikTextArea";

const PersonalDetailsWithFormik = ({
  singleUser,
  userDetails,
  setUserDetails,
  onSubmit = () => {},
}) => {
  const createdAtDate = new Date(singleUser?.createdAt);
  // const updatedAtDate = new Date(singleUser?.updatedAt);

  // DATE AND TIME IN ENGLISH
  // const dateInEnglish = `${createdAtDate.toLocaleTimeString("en-US", {
  //   hour: "numeric",
  //   minute: "numeric",
  //   hour12: true,
  // })} ${createdAtDate.toLocaleDateString("en-US", {
  //   day: "numeric",
  //   month: "short",
  //   year: "numeric",
  // })}`;

  const dateInEnglish = `${createdAtDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  })}`;

  // const dateInEnglishUpdated = `${updatedAtDate.toLocaleDateString("en-US", {
  //   day: "numeric",
  //   month: "short",
  //   year: "numeric",
  // })}`;

  // const dateInNepali = new NepaliDate(createdAtDate).format("mmmm d, yyyy");

  const initialValues = {
    username: singleUser?.username || " ",
    email: singleUser?.email || " ",
    firstName: singleUser?.firstName || " ",
    lastName: singleUser?.lastName || " ",
    phoneNumber: singleUser?.phoneNumber || " ",
    education: singleUser?.education || " ",
    address: singleUser?.location?.address || " ",
    city: singleUser?.location?.city || " ",
    country: singleUser?.location?.country || " ",
    postCode: singleUser?.location?.postCode || " ",
    about: singleUser?.about || " ",
    occupation: singleUser?.occupation || " ",
    createdAt: singleUser?.createdAt || " ",
  };

  const validationSchema = yup.object({
    username: yup.string().required("Username is required"),
    email: yup.string().required("Email is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
    education: yup.string(),
    address: yup.string(),
    city: yup.string(),
    country: yup.string(),
    about: yup.string(),
  });

  return (
    <div className="grid gap-y-2 text-sm grid-cols-1 lg:grid-cols-3 xxs:-mx-4 sm:px-4 md:p-8 dark:bg-zinc-900 dark:bg-opacity-60">
      <div className="text-black dark:text-parewacreamcanprimary-200">
        <p className="font-bold text-lg uppercase xxs:ml-2 sm:ml-0">
          Personal Details
        </p>
      </div>

      <div className="col-span-2">
        <div className="grid gap-4 gap-y-2 text-sm">
          <div className="flex xxs:px-2 sm:px-4 lg:px-10 py-10 pt-0 shadow-lg bg-zinc-200 dark:bg-zinc-800 xxs:rounded-none sm:rounded-lg">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({ setFieldValue }) => {
                return (
                  <Form>
                    <h6 className="text-black dark:text-parewacreamcanprimary-100 text-sm mb-5 font-bold mt-6 bg-zinc-300 dark:bg-zinc-700 w-fit p-2 rounded-lg">
                      1. Information
                    </h6>
                    <div className="flex flex-col w-full">
                      <div className="w-full flex px-4 gap-10">
                        <div className="relative w-full mb-3">
                          <label
                            className="text-zinc-800 dark:text-white text-xs"
                            htmlFor="username"
                          >
                            Username
                          </label>
                          <FormikInput
                            type="text"
                            name="username"
                            id="username"
                            className="px-3 py-3 text-black dark:text-zinc-400 dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                            placeholder="jessepinkman"
                            onChange={(e) => {
                              setFieldValue("username", e.target.value);
                              setUserDetails({
                                ...userDetails,
                                username: e.target.value,
                              });
                            }}
                            required={true}
                          ></FormikInput>
                        </div>

                        <div className="w-2/3">
                          <label className="text-zinc-800 dark:text-white text-xs">
                            Created At
                          </label>
                          <div className="flex w-full border-zinc-300 p-2 gap-4 items-center">
                            <p className=" text-black dark:text-zinc-300 text-xs font-mono w-1/2">
                              <span className="dark:text-parewacreamcanprimary-200 text-zinc-500 hover:opacity-40 mt-1 cursor-none">
                                {dateInEnglish}
                              </span>
                            </p>
                            <GitCommitVertical
                              size={30}
                              className="text-parewamatrixprimary-100"
                            />
                          </div>
                          <p className=" text-black dark:text-zinc-300 text-xs font-mono w-1/2">
                            <span className="dark:text-parewamatrixprimary-100 text-zinc-500 hover:opacity-40 mt-1 cursor-none">
                              {/* {dateInEnglishUpdated} */}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="flex">
                        <div className="w-full px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="text-zinc-800 dark:text-white text-xs"
                              htmlFor="firstName"
                            >
                              First Name
                            </label>
                            <FormikInput
                              type="text"
                              name="firstName"
                              id="firstName"
                              className="px-3 py-3 text-black dark:text-zinc-400 dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                              placeholder="Jesse"
                              onChange={(e) => {
                                setFieldValue("firstName", e.target.value);
                                setUserDetails({
                                  ...userDetails,
                                  firstName: e.target.value,
                                });
                              }}
                              required={true}
                            ></FormikInput>
                          </div>
                        </div>
                        <div className="w-full px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="text-zinc-800 dark:text-white text-xs"
                              htmlFor="lastName"
                            >
                              Last Name
                            </label>
                            <FormikInput
                              type="text"
                              name="lastName"
                              id="lastName"
                              className="px-3 py-3 text-black dark:text-zinc-400 dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                              placeholder="Pinkman"
                              value={singleUser?.lastName || " "}
                              onChange={(e) => {
                                setFieldValue("lastName", e.target.value);
                                setUserDetails({
                                  ...userDetails,
                                  lastName: e.target.value,
                                });
                              }}
                              required={true}
                            ></FormikInput>
                          </div>
                        </div>
                      </div>
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="text-zinc-800 dark:text-white text-xs"
                            htmlFor="phoneNumber"
                          >
                            Phone Number
                          </label>
                          <FormikInput
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            className="px-3 py-3 text-black dark:text-zinc-400 dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                            placeholder="9812345682"
                            value={singleUser?.phoneNumber || " "}
                            onChange={(e) => {
                              setFieldValue("phoneNumber", e.target.value);
                              setUserDetails({
                                ...userDetails,
                                phoneNumber: e.target.value,
                              });
                            }}
                            required={true}
                          ></FormikInput>
                        </div>
                      </div>
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="text-zinc-800 dark:text-white text-xs"
                            htmlFor="occupation"
                          >
                            Occupation
                          </label>
                          <FormikInput
                            type="text"
                            name="occupation"
                            id="occupation"
                            className="px-3 py-3 text-black dark:text-zinc-400 dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                            placeholder="Lawyer"
                            value={singleUser?.occupation || " "}
                            onChange={(e) => {
                              setFieldValue("occupation", e.target.value);
                              setUserDetails({
                                ...userDetails,
                                occupation: e.target.value,
                              });
                            }}
                          ></FormikInput>
                        </div>
                      </div>
                      <div className="w-full px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="text-zinc-800 dark:text-white text-xs"
                            htmlFor="education"
                          >
                            Education
                          </label>
                          <FormikInput
                            type="text"
                            name="education"
                            id="education"
                            className="px-3 py-3 text-black dark:text-zinc-400 dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                            placeholder="Tribhuwan University"
                            value={singleUser?.education || " "}
                            onChange={(e) => {
                              setFieldValue("education", e.target.value);
                              setUserDetails({
                                ...userDetails,
                                education: e.target.value,
                              });
                            }}
                          ></FormikInput>
                        </div>
                      </div>
                    </div>

                    <hr className="mt-2 border border-zinc-300 dark:border-zinc-900 w-1/2"></hr>
                    <h6 className="text-black dark:text-parewacreamcanprimary-100 text-sm mb-5 font-semibold mt-6 bg-zinc-300 dark:bg-zinc-700 w-fit p-2 rounded-lg">
                      2. Contact
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full px-4">
                        <div className="w-full mb-3">
                          <div className="flex mb-1">
                            <label
                              className="text-zinc-800 dark:text-white text-xs"
                              htmlFor="email"
                            >
                              Email address
                            </label>
                            {singleUser?.isVerified ? (
                              <button title="Verified">
                                <BadgeCheck
                                  size={16}
                                  strokeWidth={3}
                                  color="green"
                                  className="ml-1"
                                />
                              </button>
                            ) : (
                              <button title="Not Verified">
                                <BadgeCheck
                                  size={16}
                                  color="gray"
                                  className="ml-1"
                                />
                              </button>
                            )}
                          </div>
                          <FormikInput
                            type="email"
                            name="email"
                            id="email"
                            className="px-3 py-3 text-black dark:text-zinc-400 dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                            placeholder="jesse@example.com"
                            value={singleUser?.email || " "}
                            required={true}
                            disabled={true}
                          ></FormikInput>
                        </div>
                      </div>
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="text-zinc-800 dark:text-white text-xs"
                            htmlFor="address"
                          >
                            Address
                          </label>
                          <FormikInput
                            type="text"
                            name="address"
                            id="address"
                            className="px-3 py-3 text-black dark:text-zinc-400 dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                            placeholder="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                            value={singleUser?.location?.address || " "}
                            onChange={(e) => {
                              setFieldValue("address", e.target.value);
                              setUserDetails({
                                ...userDetails,
                                location: {
                                  ...userDetails.location,
                                  address: e.target.value,
                                },
                              });
                            }}
                          ></FormikInput>
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="text-zinc-800 dark:text-white text-xs"
                            htmlFor="city"
                          >
                            City
                          </label>
                          <FormikInput
                            type="text"
                            name="city"
                            id="city"
                            className="px-3 py-3 text-black dark:text-zinc-400 dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                            placeholder="Chaukot"
                            value={singleUser?.location?.city || " "}
                            onChange={(e) => {
                              setFieldValue("city", e.target.value);
                              setUserDetails({
                                ...userDetails,
                                location: {
                                  ...userDetails.location,
                                  city: e.target.value,
                                },
                              });
                            }}
                          ></FormikInput>
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="text-zinc-800 dark:text-white text-xs"
                            htmlFor="country"
                          >
                            Country
                          </label>
                          <FormikInput
                            type="text"
                            name="country"
                            id="country"
                            className="px-3 py-3 text-black dark:text-zinc-400 text-md dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                            placeholder="Nepal"
                            value={singleUser?.location?.country || " "}
                            onChange={(e) => {
                              setFieldValue("country", e.target.value);
                              setUserDetails({
                                ...userDetails,
                                location: {
                                  ...userDetails.location,
                                  country: e.target.value,
                                },
                              });
                            }}
                          ></FormikInput>
                        </div>
                      </div>
                      <div className="w-full lg:w-4/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="text-zinc-800 dark:text-white text-xs"
                            htmlFor="postCode"
                          >
                            Postal Code
                          </label>
                          <FormikInput
                            type="text"
                            name="postCode"
                            id="postCode"
                            className="px-3 py-3 text-black dark:text-zinc-400 text-md dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                            placeholder="44600"
                            value={singleUser?.location?.postCode || " "}
                            onChange={(e) => {
                              setFieldValue("postCode", e.target.value);
                              setUserDetails({
                                ...userDetails,
                                location: {
                                  ...userDetails.location,
                                  postCode: e.target.value,
                                },
                              });
                            }}
                          ></FormikInput>
                        </div>
                      </div>
                    </div>

                    <hr className="mt-2 border border-zinc-300 dark:border-zinc-900 w-1/3"></hr>
                    <h6 className="text-black dark:text-parewacreamcanprimary-100 text-sm mb-5 font-semibold mt-6 bg-zinc-300 dark:bg-zinc-700 w-fit p-2 rounded-lg">
                      3. About Me
                    </h6>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <FormikTextArea
                            type="text"
                            name="about"
                            id="about"
                            className="px-3 py-3 text-black dark:text-zinc-400 text-md dark:bg-zinc-700 rounded focus:outline-1 w-full transition-all duration-300"
                            placeholder="A beautiful Tailwind CSS and JavaScript."
                            rows="3"
                            onChange={(e) => {
                              setFieldValue("about", e.target.value);
                              setUserDetails({
                                ...userDetails,
                                about: e.target.value,
                              });
                            }}
                          ></FormikTextArea>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <hr className="mt-10 border-b-2 dark:border-b rounded-full dark:rounded-none border-zinc-200 dark:border-zinc-800"></hr>
    </div>
  );
};

export default PersonalDetailsWithFormik;
