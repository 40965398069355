import {
  ActivitySquare,
  Building,
  Code2,
  Map,
  Newspaper,
  Popcorn,
  Trophy,
  UsersRound,
} from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import NavButton from "./NavButtons.js";

const TopNav = () => {
  const navigate = useNavigate();

  return (
    <div className="h-fit flex-col w-full">
      <h1 className="w-full mx-auto px-2 tracking-wide uppercase font-Comfortaa flex justify-end items-center text-[10px] dark:text-parewacreamcanprimary-200 text-zinc-600 2xl:hidden xxs:h-5 sm:h-10">
        Categories
      </h1>
      <div className="w-full xxs:flex-col gap-2 2xl:hidden h-4/5 font-Comfortaa">
        <nav className="flex-col flex h-full xxs:gap-2 sm:gap-5 dark:text-zinc-300 text-black hover:text-opacity-25 justify-start items-end capitalize">
          {/* Categories Button */}
          <NavButton
            navigate={navigate}
            type="arts_%26_culture"
            Icon={UsersRound}
            label="Arts & Culture"
          />
          <NavButton
            navigate={navigate}
            type="business_%26_entrepreneurs"
            Icon={Building}
            label="Business & Entrepreneurs"
          />
          <NavButton
            navigate={navigate}
            type="celebrity_%26_pop_culture"
            Icon={Popcorn}
            label="Celebrity & Pop Culture"
          />
          <NavButton
            navigate={navigate}
            type="fitness_%26_health"
            Icon={ActivitySquare}
            label="Fitness & Health"
          />
          <NavButton
            navigate={navigate}
            type="news_%26_social_concern"
            Icon={Newspaper}
            label="News & Social"
          />
          <NavButton
            navigate={navigate}
            type="science_%26_technology"
            Icon={Code2}
            label="Science & Technology"
          />
          <NavButton
            navigate={navigate}
            type="sports"
            Icon={Trophy}
            label="Sports"
          />
          <NavButton
            navigate={navigate}
            type="travel_%26_adventure"
            Icon={Map}
            label="Travel & Adventure"
          />
          {/* Categories Button */}
        </nav>
      </div>
    </div>
  );
};

export default TopNav;
